<template>
  <div>
    <b-row>
      <b-col>
        <h2>Notifications</h2>
      </b-col>
    </b-row>
    <hr>
    <div v-if="isCreateNotification">
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Title"
            label-for="title"
          >
            <b-form-input
              id="title"
              v-model="Title"
              type="text"
              placeholder=""
              name="title"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-row class="pl-1 pb-1">
            <b-col md="0.5">
              <strong>User Must Acknowledge</strong>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <b-form-radio-group
                v-model="UserMustAcknowledge"
                :options="options"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="8">
          <b-form-group
            label="Message"
            label-for="message"
          >
            <div
              class="collapse-textarea"
              :class="{expanded: !isExpanded}"
            >
              <textarea
                v-model="Message"
                class="expandable-textarea"
                :class="[isExpanded ? 'not-expanded' : 'expanded']"
                contentEditable
              />
              <div @click="isExpanded = !isExpanded">
                <span
                  class="expand-arrow mt-n1"
                  :class="[isExpanded ? 'not-expanded' : 'expanded']"
                >&#187;</span>
              </div>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="8">
          <b-form-group
            label="URL Reference"
            label-for="message"
          >
            <div
              class="collapse-textarea"
              :class="{expanded: !isExpandedURLReference}"
            >
              <textarea
                v-model="URLReference"
                class="expandable-textarea"
                :class="[isExpandedURLReference ? 'not-expanded' : 'expanded']"
                contentEditable
              />
              <div @click="isExpandedURLReference = !isExpandedURLReference">
                <span
                  class="expand-arrow mt-n1"
                  :class="[isExpandedURLReference ? 'not-expanded' : 'expanded']"
                >&#187;</span>
              </div>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group
        label="Visibility"
        label-for="visibility"
        class="required-data-group"
      >
        <b-row id="visibility">
          <b-col md="2">
            <b-form-checkbox
              id="internalUsers"
              v-model="InternalUsers"
              name="internalUsers"
            >
              Internal Users
            </b-form-checkbox>
          </b-col>
          <b-col md="2">
            <b-form-checkbox
              id="clients"
              v-model="Clients"
              name="clients"
            >
              Clients
            </b-form-checkbox>
          </b-col>
          <b-col md="2">
            <b-form-checkbox
              id="investigators"
              v-model="Investigators "
              name="investigators"
            >
              Investigators
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-form-group>
      <b-row>
        <b-col md="2">
          <b-button
            type="submit"
            variant="primary"
            class="mr-1"
            size="md"
            @click="cancelSend()"
          >
            Cancel
          </b-button>
        </b-col>
        <b-col md="3">
          <b-button
            type="submit"
            variant="primary"
            class="mr-1"
            size="md"
            :disabled="isSending"
            @click="sendNotification()"
          >
            Send Notification
          </b-button>
        </b-col>
      </b-row>
    </div>

    <b-row v-else>
      <b-col>
        <b-button
          type="submit"
          variant="primary"
          class="mr-1"
          size="md"
          @click="createNotification()"
        >
          Create a New Notification
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BButton, BCol, BFormCheckbox, BFormGroup, BFormInput, BFormRadioGroup, BRow} from "bootstrap-vue";
import APIService from "@core/utils/APIService";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import store from "@/store";

const apiService = new APIService();

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormRadioGroup,
    BButton,
  },
  data() {
    return {
      Title: "",
      Message: "",
      URLReference: "",
      InternalUsers: false,
      UserMustAcknowledge: false,
      Clients: false,
      Investigators: false,
      Visibility: "",

      isCreateNotification: false,
      isExpanded: false,
      isExpandedURLReference: false,

      options: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
      isSending: false,
    }
  },
  watch: {
    InternalUsers(val) {
      this.changeData("User,", val)
    },
    Investigators(val) {
      this.changeData("Investigator,", val)
    },
    Clients(val) {
      this.changeData("Client,", val)
    },
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification Sent',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },

    changeData(text, inc) {
      if (inc) {
        if (this.Visibility.indexOf(text) === -1) {
          this.Visibility = this.Visibility + text
        }
      } else {
        if (this.Visibility.indexOf(text) !== -1) {
          this.Visibility = this.Visibility.replace(text, "")
        }
      }
    },

    createNotification() {
      this.isCreateNotification = true;
    },

    cancelSend() {
      this.isCreateNotification = false;
      this.Visibility = "";
      this.Title = "";
      this.Message = "";
      this.URLReference = "";
      this.InternalUsers = false;
      this.UserMustAcknowledge = false;
      this.Clients = false;
      this.Investigators = false;
    },

    sendNotification() {
      this.isSending = true;
      if (this.URLReference) {
        if (!this.isValidUrl(this.URLReference)) {
          this.showToast('danger', 'top-center', 4000, 'The URL not valid.');
          this.isSending = false;
          return;
        }
      }
      if (!this.Visibility) {
        this.showToast('danger', 'top-center', 4000, 'You should specify who can see this message.');
        this.isSending = false;
        return;
      }
      const postData = {
        "Visibility": this.Visibility,
        "Title": this.Title,
        "Message": this.Message,
        "URLReference": this.URLReference,
        "UserMustAcknowledge": this.UserMustAcknowledge,
      }
      apiService
        .post("notifications/create", postData)
        .then(res => {
          if (res) {
            this.showToast('success', 'top-right', 4000, 'The notification has been sent.');
            this.cancelSend();
            this.getNotification();
          }
          this.isSending = false
        })
    },

    getNotification() {
      if (!navigator.onLine) return;
      apiService
          .get('notifications/user')
          .then(res => {
            store.commit('notification/setNotification', res.data)
          })
    },

    isValidUrl(string) {
      try {
        new URL(string);
        return true;
      } catch (err) {
        return false;
      }
    },
  }
}
</script>

<style scoped>

</style>